* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

a {
  text-decoration: none;
}

#root {
  position: relative;
  width: 100%;
  height: 100vh;
  --app-theme-primary-color: #0095ff;
}

body {
  color: var(--tg-theme-text-color);
}

body[theme=dark] {
  --app-mobile-nav-color: #495b71;
  --app-mobile-nav-color-selected: #fff;
  --app-theme-warn-color: #dad600;
  --app-theme-loader-color: #fff;
}

body[theme=light] {
  --app-mobile-nav-color: gray;
  --app-mobile-nav-color-selected: #495b71;
  --app-theme-warn-color: #ff6a00;
  --app-theme-loader-color: #0081ff;
}

body {
  background: var(--tg-theme-bg-color);
  color: var(--tg-theme-text-color);
  font-family: Arial, serif;
  font-size: 16px;
}

div[data-disabled=true] {
  opacity: 0.4;
}

.mb-1 {
  margin-bottom: 5px;
}

.mb-2 {
  margin-bottom: 10px;
}

.text-danger {
  color: red;
}

.text-warn {
  color: var(--app-theme-warn-color);
}

.my-2 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.text-center {
  text-align: center;
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.fixed-center {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

button {
  padding: .375rem .75rem;
}

.flex-column {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.mr-1 {
  margin-right: 5px;
}

.right {
  right: 10px;
}

.position-fixed {
  position: fixed;
  z-index: 2;
}

.mr-3 {
  margin-right: 20px;
}

.ms-1{
  margin-left: 5px;
}

.d-flex {
  display: flex;
}

.d-block {
  display: block;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: end;
}

.justify-between {
  justify-content: space-between;
}

.w-50 {
  width: 50%;
}


.w-100 {
  width: 100%;
}

.mb-3 {
  margin-bottom: 15px;
}

.mt-1 {
  margin-top: 5px;
}

.mt-3 {
  margin-top: 15px;
}

.text-second {
  color: var(--tg-theme-hint-color);
}

label {
  display: block;
  margin-top: 10px;
  margin-bottom: 5px;
}

input:not([type=checkbox]), textarea {
  display: block;
  resize: none;
  background: var(--tg-theme-secondary-bg-color);
  color: var(--tg-theme-text-color);
  width: 100%;
  padding: .375rem .75rem;
  outline: none;
  border: none;
  font-size: 1rem;
  border-radius: .375rem;
  line-height: 1.5;
}

label[for] {
  user-select: none;
}

input[type=checkbox] {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.pointer {
  cursor: pointer !important;
  user-select: none;
}

input.error, textarea.error {
  border: 1px solid red;
}

span.error {
  display: block;
}

.btn {
  background-color: var(--app-theme-primary-color);
  border: none;
  outline: none;
  color: white;
  cursor: pointer;
  border-radius: 4px;
  padding: 10px 30px;
}

.btn.second {
  background-color: var(--tg-theme-secondary-bg-color);
}

.btn.danger {
  background-color: #ff0e0e;
}

.btn.outline.danger {
  background-color: transparent;
  border: 1px solid #ff0e0e;
  color: #ff0e0e;
}

.btn:hover {
  opacity: 0.8;
}